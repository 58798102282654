import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

Modal.setAppElement('#root') // replace '#root' with the id of your app element

class LookupLimit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.showModal
    };
    
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({ showModal: this.props.showModal });
    }
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
  render () {
    const { showModal } = this.state;
    return (
      <>
        {showModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 ">
                      <img
                        src="ciervo.png"
                        alt="..."
                        className="mx-auto w-16 h-16 object-contain"
                      />
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                      >
                        File Limit Reached
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          The limit for this version is 10 ABN companies. {' '}
                          <Link to="/login" className="text-blue-500 underline">
                            Sign In
                          </Link>{' '} for bulk look up or contact us {' '}
                          <a
                            href="mailto:hello@flowkai.com"
                            className="text-blue-500 underline"
                          >
                            hello@flowkai.com
                          </a>{' '} if you wanna learn more about our product.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={this.handleCloseModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default LookupLimit;