import React, { useState, useEffect } from 'react';
import { supabase } from "../supabaseClient";

const quarterToComparable = (quarter) => {
    const [year, q] = quarter.split('-Q');
    return parseInt(year) * 4 + parseInt(q);
}

const FilterQrt = ({ onQuarterChange }) => {
    const [filterQuarterEnd, setFilterQuarterEnd] = useState('2023-Q3');
    const [QuarterEnd, setQuarterEnd] = useState([]);
    const [filterQuarterStart, setFilterQuarterStart] = useState('2016-Q1');
    const [QuarterStart, setQuarterStart] = useState([]);

    useEffect(() => {
        const fetchQuarters = async () => {
            const { data: quarters, error } = await supabase.from('vw_quarters').select('time_period',{distinct: true}).order('period_order');
            if (error) {
                console.error('Error fetching index options:', error);
                return;
            }
            setQuarterStart(quarters.map(item => item.time_period));
            setQuarterEnd(quarters.map(item => item.time_period));
        }

        fetchQuarters();
    }, []);

    const handleStartChange = (e) => {
        setFilterQuarterStart(e.target.value);
        onQuarterChange(e.target.value, filterQuarterEnd);
        console.log('Selected start quarter:', e.target.value);
    }

    const handleEndChange = (e) => {
        setFilterQuarterEnd(e.target.value);
        onQuarterChange(filterQuarterStart, e.target.value);
        console.log('Selected end quarter:', e.target.value);
    }

    return (
        <div className="space-x-0.2 mp-1 p-4">
            <div className="flex flex-wrap justify-between">
                <div className="w-full sm:w-auto mb-4 sm:mb-0">
                    <label className="block text-sm font-medium text-gray-700">Start Quarter</label>
                    <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterQuarterStart} onChange={handleStartChange}>
                        {QuarterStart.map((region) => (
                            <option key={region} value={region}>{region}</option>
                        ))}
                    </select>
                </div>
                <div className="w-full sm:w-auto mb-4 sm:mb-0">
                    <label className="block text-sm font-medium text-gray-700">End Quarter</label>
                    <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterQuarterEnd} onChange={handleEndChange}>
                        {QuarterEnd.filter((region) => quarterToComparable(region) > quarterToComparable(filterQuarterStart)).map((region) => (
                            <option key={region} value={region}>{region}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default FilterQrt;