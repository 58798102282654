import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { supabase } from "../supabaseClient";
import FilterAbs from './AbsFilters';
import FilterQrt from './AbsFiltersQuarter';
import AbsDataFilter from './AbsDataFilter';
import AbsDataFilterY from './AbsDataFilterY';
import RegionFilter from './AbsRegionFilter';
import Navbar from '../components/NavbarBack';

const DualAxisLineChart = () => {
    const [cpiData, setCpiData] = useState([]);
    const [wpiData, setWpiData] = useState([]);
    const [regions, setRegions] = useState([]);
    const [filterRegions, setFilterRegions] = useState(regions[0] ? [regions[0]] : []);
    const [regionsY, setRegionsY] = useState([]);
    const [filterRegionsY, setFilterRegionsY] = useState(regionsY[0] ? [regionsY[0]] : []);
    const [selectedQuarterStart, setSelectedQuarterStart] = useState('2016-Q1');
    const [selectedQuarterEnd, setSelectedQuarterEnd] = useState('2023-Q3');
    const[selectedOrder, setSelectedOrder] = useState(20231);
    const[selectedData, setSelectedData] = useState('cpi_qrt_by_region');
    const[selectedDataY, setSelectedDataY] = useState('wpi_all_industries');
    const [measure, setMeasure] = useState([]);
    const [filterMeasure, setFilterMeasure] = useState(measure[0] ? [measure[0]] : []);
    const [measureY, setMeasureY] = useState([]);
    const [filterMeasureY, setFilterMeasureY] = useState(measureY[0] ? [measureY[0]] : []);
    const [index, setindex] = useState([]);
    const [filterIndex, setFilterIndex] = useState(index[0] ? [index[0]] : []);
    const [indexY, setindexY] = useState([]);
    const [filterIndexY, setFilterIndexY] = useState(indexY[0] ? [indexY[0]] : []);
 
    



const handleQuarterChange = (selectedQuarterStart, selectedQuarterEnd) => {
    setSelectedQuarterStart(selectedQuarterStart);
    setSelectedQuarterEnd(selectedQuarterEnd);
    console.log('Selected quarter range in parent component:', selectedQuarterStart, selectedQuarterEnd);
}

const handleChange = (selectedData) => {
    setSelectedData(selectedData);
    console.log('Selected table to see:', selectedData);
}
const handleChangeY = (selectedDataY) => {
    setSelectedDataY(selectedDataY);
    console.log('Selected table to see Y:', selectedDataY);
}

useEffect(() => {
    const fetchRegions = async () => {
        console.log('Fetching REGIONS:', filterRegions);
        let { data, error } = await supabase
            .from('vw_abs_regions')
            .select('region', { distinct: true })
            .eq('table_name', selectedData) // change this for the data selected
            .order('total_obs_value', { ascending: false });

        if (error) console.error('Error fetching regions:', error);
        else {
            const regions = data.map(item => item.region);
            setRegions(regions);
            if (regions.length > 0) {
                setFilterRegions([regions[0]]);
            }
        }
    };

    fetchRegions();
}, [selectedData]);

useEffect(() => {
    const fetchRegionsY = async () => {
        console.log('Fetching REGIONS:', filterRegionsY);
        let { data, error } = await supabase
            .from('vw_abs_regions')
            .select('region', { distinct: true })
            .eq('table_name', selectedDataY) // change this for the data selected
            .order('total_obs_value', { ascending: false });

        if (error) console.error('Error fetching regions:', error);
        else {
            const regionsY = data.map(item => item.region);
            setRegionsY(regionsY);
            if (regionsY.length > 0) {
                setFilterRegionsY([regionsY[0]]);
            }
        }
    };

    fetchRegionsY();
}, [selectedDataY]);


useEffect(() => {
    const fetchMeasures = async () => {
       
        let { data, error } = await supabase
            .from('vw_abs_measure')
            .select('measure', { distinct: true })
            .eq('table_name', selectedData) // change this for the data selected
            .order('total_obs_value', { ascending: false });

        if (error) console.error('Error fetching measure:', error);
        else {
            const measure = data.map(item => item.measure);
            setMeasure(measure);
            if (measure.length > 0) {
                setFilterMeasure([measure[0]]);
            }
        }
    };

    fetchMeasures();
}, [selectedData]);


useEffect(() => {
    const fetchMeasuresY = async () => {
       
        let { data, error } = await supabase
            .from('vw_abs_measure')
            .select('measure', { distinct: true })
            .eq('table_name', selectedDataY) // change this for the data selected
            .order('total_obs_value', { ascending: false });

        if (error) console.error('Error fetching measure:', error);
        else {
            const measureY = data.map(item => item.measure);
            setMeasureY(measureY);
            if (measureY.length > 0) {
                setFilterMeasureY([measureY[0]]);
            }
        }
    };

    fetchMeasuresY();
}, [selectedDataY]);

useEffect(() => {
    const fetchIndex = async () => {
       
        let { data, error } = await supabase
            .from('vw_abs_index')
            .select('index', { distinct: true })
            .eq('table_name', selectedData) // change this for the data selected
            .order('total_obs_value', { ascending: false });

        if (error) console.error('Error fetching index:', error);
        else {
            const index = data.map(item => item.index);
            setindex(index);
            if (index.length > 0) {
                setFilterIndex([index[0]]);
            }
        }
    };

    fetchIndex();
}, [selectedData]);

useEffect(() => {
    const fetchIndexY = async () => {
       
        let { data, error } = await supabase
            .from('vw_abs_index')
            .select('index', { distinct: true })
            .eq('table_name', selectedDataY) // change this for the data selected
            .order('total_obs_value', { ascending: false });

        if (error) console.error('Error fetching index:', error);
        else {
            const indexY = data.map(item => item.index);
            setindexY(indexY);
            if (indexY.length > 0) {
                setFilterIndexY([indexY[0]]);
            }
        }
    };

    fetchIndexY();
}, [selectedDataY]);





useEffect(() => {
    const fetchCpiData = async () => {
        console.log('Fetching data from table:', selectedData);
        let { data: cpiData } = await supabase
            .from(selectedData)
            .select('time_period, obs_value')
            .gte('time_period', selectedQuarterStart)
            .lte('time_period', selectedQuarterEnd)
            .eq('region', filterRegions)
            .eq('measure', filterMeasure)
            .eq('index', filterIndex)
    
            .order('time_period', { ascending: true });

        setCpiData(cpiData);
    };

    const fetchWpiData = async () => {
        console.log('Fetching data from table Y:', selectedDataY);
        let { data: wpiData } = await supabase
            .from(selectedDataY)
            .select('time_period, obs_value')
            .gte('time_period', selectedQuarterStart)
            .lte('time_period', selectedQuarterEnd)
            .eq('region', filterRegionsY)
            .eq('measure', filterMeasureY)
            .eq('index', filterIndexY)
            .order('time_period', { ascending: true });

        setWpiData(wpiData);
    };

    fetchCpiData();
    fetchWpiData();
  
}, [selectedData, selectedDataY, selectedQuarterStart, selectedQuarterEnd, filterRegions, filterRegionsY, filterMeasure, filterMeasureY, filterIndex, filterIndexY]); // added selectedQuarterStart and selectedQuarterEnd to the dependency array


    const data = {
        labels: cpiData.map(item => item.time_period),
        datasets: [
            {
                label: selectedData,
                data: cpiData.map(item => item.obs_value),
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                yAxisID: 'y-axis-1',
            },
            {
                label: selectedDataY,
                data: wpiData.map(item => item.obs_value),
                fill: false,
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgba(54, 162, 235, 0.2)',
                yAxisID: 'y-axis-2',
            },
        ],
    };

    const options = {
        scales: {
            'y-axis-1': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: selectedData+" ==> BY ==> "+ filterMeasure
                }
            },
            'y-axis-2': {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                title: {
                    display: true,
                    text: selectedDataY+" ==> BY ==> "+ filterMeasure
                }
            },
        },
    };

    return (
        <div className="space-x-0.1">
        <div className="mb-1">
          <Navbar />
        </div>
             <div
        className="content-center items-center justify-center mb-1"
        style={{ background: "linear-gradient(to right, #2c82f2 ,#2c32f2)" }}
        
      >
        <h1 className="mt-1 flex text-gray-100  font-semibold text-3xl content-center items-center justify-center font-mono">
          FlowKAI - ABS Quarterly Correlation
        </h1>

      </div>
      
        <div className="w-full  lg:mb-0 mb-12 px-1 border-2 bg-gradient-to-b from-gray-200 to-gray-50  rounded">
 <div className = 'flex flex-wrap justify-between mp-4 p-4'>
    <AbsDataFilter onDataChange= {handleChange}/>
    <AbsDataFilterY onDataChange= {handleChangeY}/>
</div>

            <FilterQrt onQuarterChange={handleQuarterChange} />
            <div className="space-x-0.2 mp-1 p-4">
            <div className="flex flex-wrap justify-between">
                <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Regions </label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterRegions} onChange={(e) => setFilterRegions(e.target.value)}>
            <option value="">Select the Regions</option>
            {regions.map((index) => (
                <option key={index} value={index}>{index}</option>
            ))}
        </select>
        </div>
                <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Regions Y</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterRegionsY} onChange={(e) => setFilterRegionsY(e.target.value)}>
            <option value="">Select the Regions</option>
            {regionsY.map((index) => (
                <option key={index} value={index}>{index}</option>
            ))}
        </select>
        </div>
            </div>
        </div>
        <div className="space-x-0.2 mp-1 p-4">
            <div className="flex flex-wrap justify-between">
  
                <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Measure</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterMeasure} onChange={(e) => setFilterMeasure(e.target.value)}>
            <option value="">Select Measures</option>
            {measure.map((index) => (
                <option key={index} value={index}>{index}</option>
            ))}
        </select>
        </div>
        <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Measure Y</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterMeasureY} onChange={(e) => setFilterMeasureY(e.target.value)}>
            <option value="">Select Measures</option>
            {measureY.map((index) => (
                <option key={index} value={index}>{index}</option>
            ))}
        </select>
        </div>
        </div>
        </div>
        <div className="space-x-0.2 mp-1 p-4">
            <div className="flex flex-wrap justify-between">
        <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Index</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterIndex} onChange={(e) => setFilterIndex(e.target.value)}>
            <option value="">Select Index</option>
            {index.map((index) => (
                <option key={index} value={index}>{index}</option>
            ))}
        </select>
        </div>
        <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Index Y</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterIndexY} onChange={(e) => setFilterIndexY(e.target.value)}>
            <option value="">Select Index</option>
            {indexY.map((index) => (
                <option key={index} value={index}>{index}</option>
            ))}
        </select>
        </div>
        </div>
        </div>
        </div>
           
            <Line data={data} options={options} />
        </div>
    );
};

export default DualAxisLineChart;