import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(...registerables);

function BarChartReact({ data, xField, yField }) {
  // Sort the data by yField
  const sortedData = [...data].sort((a, b) => (a[yField] > b[yField] ? -1 : 1));

  // Prepare the data for the chart
  const chartData = {
    labels: sortedData.map((item) => item[xField]),
    datasets: [
      {
        label: yField,
        data: sortedData.map((item) => item[yField]),
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  return (
    <div>
      <Bar
        data={chartData}
        options={{
          indexAxis: "y",
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  );
}

export default BarChartReact;
