import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "./supabaseClient";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import Navbar from "./components/NavbarBack";

export default function Signin() {
  const [session, setSession] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  if (!session) {
    return (
      <div>
        <div className="mb-0.5 relative z-10">
          <Navbar />
        </div>
        <div className="flex items-center justify-center min-h-screen bg-gray-100" id= 'login'>
          <div className="w-full max-w-md space-y-1 min-h-[90vh] bg-white rounded-lg shadow-md">
            <div className="relative text-center min-h-[18vh] font-bold text-xl mb-4 rounded-lg shadow-md">
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  zIndex: 1,
                  borderTopLeftRadius: "0.5rem",
                  borderTopRightRadius: "0.5rem"
                }}
              >
                <source src="progam1.mp4" type="video/mp4" />
              </video>
<div style={{position: 'absolute', zIndex: 2, top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
  <h1 className="text-white font-semibold text-xl content-center items-center justify-center font-mono">
    FlowKAI
  </h1>
<p className="mt-4 text-sm font-semibold text-white content-center items-center justify-center whitespace-nowrap">
  Transforming Enterprises Through Technology
</p>
</div>
            </div>
            <div className="text-center px-8 py-3 text-gray-500">
            <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={['google', 'github']} />
          </div>
          </div>
        </div>
      </div>
    );
  } else {
    return navigate();
  }
}