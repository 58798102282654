import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import BarChartReactRaw from "./BarChartReact";

const BarAbnStatus = () => {
  const [chartData, setChartData] = useState([]);

  // Fetch initial data
  const fetchInitialData = async () => {
    let { data: chartEntType, error } = await supabase
      .from("abn_alpha_lookup")
      .select("abn_status");

    if (error) console.log("Data fetching error: ", error);
    else {
      const groupedData = chartEntType.reduce((acc, curr) => {
        acc[curr.abn_status] = (acc[curr.abn_status] || 0) + 1;
        return acc;
      }, {});

      const newChartData = Object.entries(groupedData).map(
        ([abn_status, count]) => {
          const shortEntityType = abn_status.split("=>")[0].trim(); // Shorten the abn_status value
          return { abn_status: shortEntityType, count };
        }
      );

      setChartData(newChartData);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Set up real-time subscription
  useEffect(() => {
    const channel = supabase
      .channel("db-changes")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "abn_alpha_lookup" },
        (payload) => {
          setChartData((prevRecords) => [payload.new]);
          fetchInitialData();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      {chartData.length > 0 && (
        <BarChartReactRaw data={chartData} xField="abn_status" yField="count" />
      )}
    </div>
  );
};

export default BarAbnStatus;
