import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer className="relative bg-gray-300 pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold">Let's keep in touch!</h4>
            <h5 className="text-lg mt-0 mb-2 text-gray-700">
              Contact us using our contact form or email us at{" "}
              <a className="underline" href="mailto:hello@flowkai.com">
                hello@flowkai.com
              </a>
            </h5>
            <div className="mt-6">
              <button
                className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <i className="flex fab fa-twitter"></i>
              </button>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Useful Links
                </span>
                <ul className="list-unstyled">
                  <li>
                    <Link>
                      <ScrollLink
                        to="Meetourteam"
                        smooth={true}
                        duration={500}
                        className={
                          "text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        }
                      >
                        About Us
                      </ScrollLink>
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <ScrollLink
                        to="contactForm"
                        smooth={true}
                        duration={500}
                        className={
                          "text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        }
                      >
                        Contact Us
                      </ScrollLink>
                    </Link>
                  </li>

                  <li>
                    <Link>
                      <ScrollLink
                        to="Services"
                        smooth={true}
                        duration={500}
                        className={
                          "text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        }
                      >
                        Services
                      </ScrollLink>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Other Resources
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="https://www.business.qld.gov.au/starting-business/starting-buying/buying/due-diligence"
                    >
                      Due diligence when buying
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="https://www.business.qld.gov.au/starting-business/starting-buying/buying/due-diligence"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      href="https://www.oaic.gov.au/privacy/australian-privacy-principles"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <Link>
                      <ScrollLink
                        to="contactForm"
                        smooth={true}
                        duration={500}
                        className={
                          "text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        }
                      >
                        Contact Us
                      </ScrollLink>
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()} FlowKAI by{" "}
              <a
                href="https://www.linkedin.com/in/juan-biestro-2664473a/"
                className="text-gray-600 hover:text-gray-900"
              >
                Juan Biestro
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
