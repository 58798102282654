import React, { useState } from "react";
import BarAbnStatus from "./graphs/BarAbnStatus";
import BarEntityType from "./graphs/BarEntityType";
import BarAddState from "./graphs/BarAddressState";
import BarGstStatus from "./graphs/BarGstStatus";
import AbnTablePreview from "./TableResults";

function Navigation() {
  const [selectedTab, setSelectedTab] = useState("ABNStatus");
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const tabs = [
    { name: "ABN Status", id: "ABNStatus" },
    { name: "Entity Type", id: "EntityType" },
    { name: "Address State", id: "State" },
    { name: "GST Status", id: "gststatus" },
    { name: "Table View", id: "table" },
  ];
  return (
    <div>
      <div>
        {tabs.map((tab, tabIdx) => (
          <button
            key={tabIdx}
            onClick={() => setSelectedTab(tab.id)}
            className={classNames(
              tab.id === selectedTab
                ? "text-gray-900 border-gray-400 bg-gray-50"
                : "text-gray-500 hover:text-gray-700 border-gray-100",
              "m-2 border-2 rounded-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
          >
            {tab.name}
          </button>
        ))}
      </div>
      {selectedTab === "ABNStatus" && <BarAbnStatus />}
      {selectedTab === "State" && <BarAddState />}
      {selectedTab === "EntityType" && <BarEntityType />}
      {selectedTab === "gststatus" && <BarGstStatus />}
      {selectedTab === "table" && <AbnTablePreview />}
    </div>
  );
}

export default Navigation;
