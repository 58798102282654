import React, { useState, useEffect } from "react";

import { supabase } from "./supabaseClient";

function AbnTablePreview() {
  const [data, setData] = useState([]);

  const fetchInitialData = async () => {
    const { data, error } = await supabase
      .from("abn_alpha_lookup")
      .select("*")
      .order("created_at", { ascending: false })
      .limit(10);

    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setData(data);
    }
  };
  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    const channel = supabase
      .channel("db-changes")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "abn_alpha_lookup" },
        (payload) => {
          setData((prevRecords) => [payload.new]);
          fetchInitialData();
        }
      )
      .subscribe();

    // Unsubscribe when the component is unmounted
    return () => supabase.removeChannel(channel);
  }, []);

  const [numRows, setNumRows] = useState(10);
  const [selectAll, setSelectAll] = useState(false);

  const handleNumRowsChange = (event) => {
    setNumRows(event.target.value);
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
  };

  return (
    <div className="flex flex-col items-center justify-center min-w-full mx-2 p-2 max-w-full overflow-auto border-double border-black">
      <h1 className="text-2xl font-bold mb-5">ABN Results</h1>

      <div className="overflow-x-auto">
        <table className="text-xs border-double border-black min-w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-sx">ABN</th>
              <th className="px-4 py-2">ABN Status</th>
              <th className="px-4 py-2">ABN Status Effective From</th>
              <th className="px-4 py-2">ACN</th>
              <th className="px-4 py-2">Address Date</th>
              <th className="px-4 py-2">Address Postcode</th>
              <th className="px-4 py-2">Address State</th>
              <th className="px-4 py-2">Business Name</th>
              <th className="px-4 py-2">Entity Name</th>
              <th className="px-4 py-2">Entity Type Code</th>
              <th className="px-4 py-2">Entity Type Name</th>
              <th className="px-4 py-2">GST</th>
              <th className="px-4 py-2">GST Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-200" : ""}>
                <td className="border px-4 py-2  text-sx">{item.abn}</td>
                <td className="border px-4 py-2">{item.abn_status}</td>
                <td className="border px-4 py-2">
                  {item.abn_status_effective_from}
                </td>
                <td className="border px-4 py-2 text-sx">{item.acn}</td>
                <td className="border px-4 py-2">{item.address_date}</td>
                <td className="border px-4 py-2">{item.address_postcode}</td>
                <td className="border px-4 py-2">{item.address_state}</td>
                <td className="border px-4 py-2">{item.business_name}</td>
                <td className="border px-4 py-2">{item.entity_name}</td>
                <td className="border px-4 py-2">{item.entity_type_code}</td>
                <td className="border px-4 py-2">{item.entity_type_name}</td>
                <td className="border px-4 py-2">{item.gst}</td>
                <td className="border px-4 py-2">{item.gst_status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AbnTablePreview;
