// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRef,
  Switch,
} from "react-router-dom";

import Landing from "./Home";
import Navbar from "./components/Navbar";
import ContactForm from "./ContactForm";
import AbnLookUp from "./AbnLookup";
import Login from "./Login";
import Navigation from "./FullscreenResults";
import AbnTablePreview from "./TableResults";
import Logout from "./LogOut";
import LineChart from "./graphs/AbsChart";
import DualAxisLineChart from "./graphs/AbsChartCorrelation";


const Home = () => (
  <div>
    <Navbar transparent />
    {/* <Header /> */}
    <Landing />
  </div>
);

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact-form" element={<ContactForm />} />
      <Route path="/abn-lookup" element={<AbnLookUp />} />
      <Route path="/full-screen-results" element={<Navigation />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/cpi-monthly" element={<LineChart />} />
      <Route path="/quarterly-correlation" element={<DualAxisLineChart />} />
      
      {/* <Route path="/abn-table-preview" element={<AbnTablePreview />} /> */}
    </Routes>
  </Router>
);

export default App;
