import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";

function DownloadResults() {
  const [numRows, setNumRows] = useState(10);
  const [selectAll, setSelectAll] = useState(false);

  const handleNumRowsChange = (event) => {
    setNumRows(event.target.value);
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
  };

  const downloadCSV = async () => {
    const { data, error } = await supabase
      .from("abn_alpha_lookup")
      .select("*")
      .limit(selectAll ? undefined : numRows);

    const csv = data.map((row) => Object.values(row).join(",")).join("\n");
    const csvData = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);

    let link = document.createElement("a");
    link.href = csvUrl;
    link.download = "data.csv";
    link.click();
  };

  return (
    <div className="flex flex-col items-center justify-center min-w-full mx-2 p-2 max-w-full overflow-auto sm:flex-row sm:space-x-4">
      <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-x-4 bg-gray-20 border border-black p-4 m-4">
        <input
          type="number"
          value={numRows}
          onChange={handleNumRowsChange}
          className="mr-2 border-2 border-blue-500 bg-gray-100"
        />
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
            className="mr-2"
          />
          <label>Download All Records</label>
        </div>
        <button
          onClick={downloadCSV}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Download Results
        </button>
      </div>
    </div>
  );
}

export default DownloadResults;
