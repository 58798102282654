import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Headroom from "react-headroom";
import Dropdown from "./Dropdown";
import { supabase } from "../supabaseClient";

function Navbar(props) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session ? session.user : null);
      }
    );

    checkUser();

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  async function checkUser() {
    const session = supabase.auth.getSession();
    setUser(session ? session.user : null);
    setUser(user);
  }

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error("Error logging out:", error.message);
    setUser(null);
  };

  // ...
  return (
    <Headroom>
      <nav
        className={
          (props.transparent
            ? "top-0 fixed z-50 w-full bg-gray-900 border-b border-white"
            : "relative shadow-lg bg-gray-800") +
          " flex flex-wrap justify-between lg:justify-around"
        }
      >
        <div className="container px-4 flex flex-row items-center mx-auto bg-gray-900 justify-between">
          <div className="relative flex justify-between w-auto">
            <Link
              to="/"
              className={
                (props.transparent ? "text-white" : "text-gray-800") +
                " text-sm font-medium leading-relaxed mr-4 py-4 whitespace-nowrap uppercase cursor-pointer"
              }
            >
              <img
                src="/icon2.png"
                alt="Logo"
                className="logo w-10 h-10 rounded-full"
              />
            </Link>
            <Link
              to="/"
              className={
                "text-white" +
                " text-sm font-medium leading-relaxed mr-4 py-4 whitespace-nowrap uppercase cursor-pointer"
              }
            >
              Home
            </Link>
          </div>
          <div
            id="navlinks"
            className="w-full relative flex justify-center lg:w-auto lg:static lg:block ml-9"
          >
            <Dropdown />
          </div>

          <div className="relative flex justify-end w-full lg:w-auto">
            {user ? (
              <>
                <div className="flex items-center">
                  <span className="text-sm text-gray-100 mr-4">
                    Welcome, {user.email}
                  </span>
                  <button
                    onClick={handleLogout}
                    className="block m-4 px-4 py-2 text-sm text-gray-100 bg-blue-700 hover:bg-gray-200 hover:text-gray-900"
                    role="menuitem"
                  >
                    Log out
                  </button>
                </div>
              </>
            ) : (
              <Link
                to="/login"
                className="block m-4 px-4 py-2 text-sm text-gray-100 bg-gradient-to-b from-blue-700 to-blue-300 hover:bg-gray-200 hover:text-gray-900"
                role="menuitem"
              >
                Sign In
              </Link>
            )}
          </div>
        </div>
      </nav>
    </Headroom>
  );
  // ...
}

export default Navbar;
