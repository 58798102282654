import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import BarChartReactRaw from "./BarChartReact";

const BarAddState = () => {
  const [chartData, setChartData] = useState([]);

  // Fetch initial data
  const fetchInitialData = async () => {
    let { data: chartEntType, error } = await supabase
      .from("abn_alpha_lookup")
      .select("address_state");

    if (error) console.log("Data fetching error: ", error);
    else {
      const groupedData = chartEntType.reduce((acc, curr) => {
        acc[curr.address_state] = (acc[curr.address_state] || 0) + 1;
        return acc;
      }, {});

      const newChartData = Object.entries(groupedData).map(
        ([address_state, count]) => {
          const shortEntityType = address_state.split("=>")[0].trim(); // Shorten the address_state value
          return { address_state: shortEntityType, count };
        }
      );

      setChartData(newChartData);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Set up real-time subscription
  useEffect(() => {
    const channel = supabase
      .channel("db-changes")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "abn_alpha_lookup" },
        (payload) => {
          setChartData((prevRecords) => [payload.new]);
          fetchInitialData();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      {chartData.length > 0 && (
        <BarChartReactRaw
          data={chartData}
          xField="address_state"
          yField="count"
        />
      )}
    </div>
  );
};

export default BarAddState;
