import React, { useState, useEffect } from "react";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { Auth } from "@supabase/auth-ui-react";
import { supabase } from "./supabaseClient";

import MyComponent from "./ScriptResults";

function AbnLookUp() {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUserId(session ? session.user : null);
      }
    );

    checkUser();

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  async function checkUser() {
    const session = supabase.auth.getSession();
    setUserId(session ? session.user : null);
  }

  return <div><MyComponent /></div>;
}

export default AbnLookUp;