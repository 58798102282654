import React, { useState } from 'react';

const options = {
    
    'wpi_all_industries': 'Wage Price Index By Region',
    'cpi_qrt_by_region': 'CPI Quarterly By Region'
};

const AbsDataFilterY = ({ onDataChange }) => {
    const handleDataChange = (event) => {
        // Get the selected friendly name
        const selectedFriendlyName = event.target.value;

        // Find the corresponding key
        const selectedKey = Object.keys(options).find(key => options[key] === selectedFriendlyName);

        // Call the onDataChange prop with the key
        onDataChange(selectedKey);
    };

    return (
        <div className="space-x-0.2">
        <div className="flex flex-wrap justify-between">
            <div className="w-full sm:w-auto mb-4 sm:mb-0">
                <label className="block text-sm font-medium text-gray-700">Secundary Axis</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" onChange={handleDataChange}>
            {Object.values(options).map(friendlyName => (
                <option key={friendlyName} value={friendlyName}>
                    {friendlyName}
                </option>
            ))}
        </select> 
            </div>  </div>   </div>
    );
};

export default AbsDataFilterY;