import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import BarChartReactRaw from "./BarChartReact";

const BarEntityType = () => {
  const [chartData, setChartData] = useState([]);

  // Fetch initial data
  const fetchInitialData = async () => {
    let { data: chartEntType, error } = await supabase
      .from("abn_alpha_lookup")
      .select("entity_type_name");

    if (error) console.log("Data fetching error: ", error);
    else {
      const groupedData = chartEntType.reduce((acc, curr) => {
        acc[curr.entity_type_name] = (acc[curr.entity_type_name] || 0) + 1;
        return acc;
      }, {});

      const newChartData = Object.entries(groupedData).map(
        ([entity_type_name, count]) => {
          const shortEntityType = entity_type_name.split("=>")[0].trim(); // Shorten the entity_type_name value
          return { entity_type_name: shortEntityType, count };
        }
      );

      setChartData(newChartData);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Set up real-time subscription
  useEffect(() => {
    const channel = supabase
      .channel("db-changes")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "abn_alpha_lookup" },
        (payload) => {
          setChartData((prevRecords) => [payload.new]);
          fetchInitialData();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      {chartData.length > 0 && (
        <BarChartReactRaw
          data={chartData}
          xField="entity_type_name"
          yField="count"
        />
      )}
    </div>
  );
};

export default BarEntityType;
