import { useState, useEffect } from "react";

import { supabase } from "./supabaseClient";

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const ContactForm = () => {
  const [full_name, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted) {
      // Clear form after submission
      setFullname("");
      setEmail("");
      setMessage("");
      setEmailError("");
    }
  }, [submitted]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isEmail(email)) {
      setEmailError("Invalid email address, please enter a valid email.");
      return;
    }

    if (message && message.length <= 200) {
      setIsUploading(true);

      const { data, error } = await supabase.from("contact_us").insert([
        {
          full_name,
          email,
          message,
        },
      ]);

      setIsUploading(false);

      if (error) {
        console.error("There was an error", error);
        return;
      }

      setSubmitted(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex-auto p-5 lg:p-10 w-full">
      {submitted ? (
        <div className="text-center">
          <p className="text-xl font-semibold text-gray-700 mb-4">
            Thank you for your message! We'll get back to you soon.
          </p>
        </div>
      ) : (
        <>
          <h4 className="text-2xl font-semibold text-gray-700 mb-4 place-content-center">
            Want to get on board? - Contact Us !!!
          </h4>
          <p className="leading-relaxed mt-1 mb-4 text-gray-700">
            Complete this form and we will get back to you in 24 hours.
          </p>

          {/* Full Name */}
          <div className="relative w-full mb-3 mt-8">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="full-name"
            >
              Full Name
            </label>
            <input
              type="text"
              value={full_name}
              onChange={(e) => setFullname(e.target.value)}
              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
              placeholder="Full Name"
            />
          </div>

          {/* Email */}
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
              placeholder="example@example.com"
            />
            {emailError && <p>{emailError}</p>}
          </div>

          {/* Message */}
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="4"
              cols="80"
              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
              placeholder="Type a message..."
            />
          </div>

          {/* Submit Button */}
          <div className="text-center mt-6">
            <button
              className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="submit"
            >
              {isUploading ? "Sending..." : "Send Message"}
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default ContactForm;
