import { supabase } from "./supabaseClient";

function Logout() {
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error("Error logging out:", error.message);
    // handle successful logout here, like redirecting to a login page
  };

  return <button onClick={handleLogout}>Logout</button>;
}

export default Logout;
