import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function Submenu({ label, links }) {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();
  const timer = useRef();

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMouseEnter = () => {
    clearTimeout(timer.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timer.current = setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  return (
    <div className="relative" ref={node} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer">
        {label} {isOpen ? <span>&#9650;</span> : <span>&#9660;</span>}
      </span>
      {isOpen && (
        <div className="origin-top-right absolute right-0 sm:absolute sm:right-0 sm:w-56 md:relative md:w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20">
          {links.map((link, index) => (
            <Link
              key={index}
              to={link.to}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              {link.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();
  const timer = useRef();

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMouseEnter = () => {
    clearTimeout(timer.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timer.current = setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  return (
    <div className="relative inline-block text-left z-10" ref={node} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button className="text-sm font-medium leading-relaxed mr-4 py-4 whitespace-nowrap uppercase cursor-pointer text-white hover:text-gray-200">
        Solutions {isOpen ? <span>&#9650;</span> : <span>&#9660;</span>}
      </button>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20">
          <div className="py-1" role="menu" aria-orientation="vertical">
            <Link to="/abn-lookup" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
              ABN Lookup
            </Link>
            <Submenu
              label="ABS CPI"
              links={[
                { to: "/cpi-monthly", label: "CPI Monthly" },
                { to: "/quarterly-correlation", label: "Quarterly Correlation" },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;