import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { supabase } from "../supabaseClient";
import Navbar from '../components/NavbarBack';

const LineChart = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{ data: [] }]
    });

    const [filterIndex, setFilterIndex] = useState('All groups CPI');
    const [indexOptions, setIndexOptions] = useState([]);
    const [filterUnit, setFilterUnit] = useState('Index Numbers'); // Add a state variable for the unit_of_measure filter
    const [unitOptions, setUnitOptions] = useState([]); // Add a state variable for the unit_of_measure options
    const [legend, setLegend] = useState(null);
    const [selectedTimePeriodStart, setSelectedTimePeriodStart] = useState([]);
    const [FilterPeriod, setFilterPeriod] = useState(null);
    const [selectedTimePeriodEnd, setSelectedTimePeriodEnd] = useState([]);
    const [data, setData] = useState([]);
    const [FilterPeriodEnd, setFilterPeriodEnd] = useState(null); // Add this line
    const [adjType, setAdjType] = useState([]);
    const [filterAdjType, SetfilterAdjType] = useState('Original');

    useEffect(() => {
        const fetchIndexOptions = async () => {
            const { data, error } = await supabase.from('cpi_index_view').select('index',{distinct: true}).order('index');
            if (error) {
                console.error('Error fetching index options:', error);
                return;
            }
            setIndexOptions(data.map(item => item.index));

            const { data: unitData, error: unitError } = await supabase.from('cpi_uomeasure_view').select('measure', { distinct: true }); // Fetch unit_of_measure options
            if (unitError) {
                console.error('Error fetching unit options:', unitError);
                return;
            }
            setUnitOptions(unitData.map(item => item.measure));
            setLegend(unitData.length > 0 ? [unitData[0].measure] : []);    
        };
        const fetchAdjType = async () => {
        const { data: adjData, error: adjError } = await supabase.from('vw_adjustment_type').select('adjustment_type', { distinct: true }); // Fetch unit_of_measure options
        if (adjError) {
            console.error('Error fetching unit options:', adjError);
            return;
        }
        // setUnitOptions(unitData.map(item => item.measure));
        setAdjType(adjData.map(item => item.adjustment_type));    
    };
        const fetchTimePeriodOptions = async () => {
            const { data: startPeriod, error: startError }  = await supabase.from('cpi_time_period').select('time_period_date', { distinct: true }).order('time_period_date', {ascending: true});
            if (startError) {
                console.error('Error fetching time period options:', startError);
                return;
            }
            setSelectedTimePeriodStart(startPeriod.map(item => item.time_period_date));
            setFilterPeriod(startPeriod[0]?.time_period_date);  // Set the min time period as default start period
            
        };


        fetchIndexOptions();
        fetchTimePeriodOptions();
        fetchAdjType();
        
    }, []);


    useEffect(() => {
        const fetchTimePeriodOptionsEnd = async () => {
            const { data: endPeriod, error: startError }  = 
            await supabase.from('cpi_time_period').select('time_period_date', { distinct: true }).order('time_period_date', {ascending: true});
            if (startError) {
                console.error('Error fetching time period options:', startError);
                return;
            }
       
                // After fetching time period options, set the default values
    setSelectedTimePeriodEnd(endPeriod.map(item => item.time_period_date));
    setFilterPeriodEnd(endPeriod[endPeriod.length - 1]?.time_period_date);  // Set the max time period as default end period
        };
        fetchTimePeriodOptionsEnd();
    },[]);

   useEffect(() => {
    const fetchData = async () => {
        // Fetch data from Supabase table
        const { data, error } = await supabase
            .from('cpi_test')
            .select('*')
            .gte('time_period_date', FilterPeriod)
            .lte('time_period_date', FilterPeriodEnd)
            .filter('index', 'eq', filterIndex)
            .filter('measure', 'eq', filterUnit)
            .filter('adjustment_type', 'eq', filterAdjType); 

        if (error) {
            console.error('Error fetching data:', error);
            return;
        }

        // Filter data within the selected time period range
        const filteredData = data.filter(
            (item) =>
                new Date(item.time_period_date) >= new Date(selectedTimePeriodStart)
                // && new Date(item.time_period_date) <= new Date(selectedTimePeriodEnd)
        );

        setData(data); // Update the data state variable

        // Process data for chart
        const sortedData = [...data].sort((a, b) => new Date(a.time_period_date) - new Date(b.time_period_date));
        const labels = sortedData.map((item) => item.time_period);
        const values = sortedData.map((item) => item.obs_value);
        const unit = sortedData.map((item) => item.measure); // Add a variable to store the unit_of_measure value
        const legend = sortedData[0]?.measure; // Add a variable to store the index value
        const legend1 = sortedData[0].reference_base_period ;
        const test = legend1 + '  '+legend;

        // Set chart data
        setChartData({
            labels,
            datasets: [
                {
                    label: legend,
                    data: values, 
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1,
                },
            ],
        });
        const timePeriods = [...new Set(filteredData.map((item) => new Date(item.time_period_date).toLocaleDateString()))]; // Define timePeriods here
        //setSelectedTimePeriodEnd(timePeriods[timePeriods.length - 1]);
    };

    if (filterIndex !== null && filterUnit !== null ) {
        fetchData();
    }
}, [filterIndex, filterUnit, FilterPeriod, FilterPeriodEnd, selectedTimePeriodStart, selectedTimePeriodEnd]);
    return (
        <div className="space-x-0.1">
        <div className="mb-1">
          <Navbar />
        </div>
             <div
        className="content-center items-center justify-center mb-1"
        style={{ background: "linear-gradient(to right, #2c82f2 ,#2c32f2)" }}
        
      >
        <h1 className="mt-1 flex text-gray-100  font-semibold text-3xl content-center items-center justify-center font-mono">
          FlowKAI - ABS CPI Monthly
        </h1>

      </div>
        <div>
        <div className="w-full  lg:mb-0 mb-12 px-1 border-2 bg-gradient-to-b from-gray-200 to-gray-50  rounded">
<div className="flex flex-wrap justify-between  mp-1 p-4">
    <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Index</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterIndex} onChange={(e) => setFilterIndex(e.target.value)}>
            <option value="">Select index</option>
            {indexOptions.map((index) => (
                <option key={index} value={index}>{index}</option>
            ))}
        </select>
    </div>

    <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Unit</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterUnit} onChange={(e) => setFilterUnit(e.target.value)}> 
            <option value="">Select unit</option>
            {unitOptions.map((unit) => (
                <option key={unit} value={unit}>{unit}</option>
            ))}
        </select>
    </div>
    {/* <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Unit</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={filterAdjType} onChange={(e) => SetfilterAdjType(e.target.value)}> 
            <option value="">Select Adjustment Type</option>
            {adjType.map((unit) => (
                <option key={unit} value={unit}>{unit}</option>
            ))}
        </select>
    </div> */}

    <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">Start Time Period</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={FilterPeriod} onChange={(e) => setFilterPeriod(e.target.value)}>
            <option value="">Select start time period</option>
            {selectedTimePeriodStart.map((timePeriod) => (
                <option key={timePeriod} value={timePeriod}>
                    {timePeriod}
                </option>
            ))}
        </select>
    </div>

    <div className="w-full sm:w-auto mb-4 sm:mb-0">
        <label className="block text-sm font-medium text-gray-700">End Time Period</label>
        <select className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" value={FilterPeriodEnd} onChange={(e) => setFilterPeriodEnd(e.target.value)}>
            <option value="">Select end time period</option>
            {selectedTimePeriodEnd.filter(timePeriod => new Date(timePeriod) > new Date(FilterPeriod)).map((timePeriod) => (
                <option key={timePeriod} value={timePeriod}>
                    {timePeriod}
                </option>
            ))}
        </select>
    </div>
</div>
<div className="w-full sm:w-auto mb-1 sm:mb-0  flex items-center justify-center">
  {filterUnit === 'Index Numbers' && <div className="text-center text-xs mx-auto line-clamp-5">September 2017 = 100.0</div>}
</div>
</div>

            <Line className= 'mp-1 p-4' data={chartData}     options={{
                        title: {
                            display: true,
                            text: "data.label1",
                        },
        tooltips: {
            callbacks: {
                title: function(tooltipItem, data) {
                    return data.labels[tooltipItem[0].index].split('\n');
                }
            }
        }
    }} />
        </div>
        </div>
    );
};

export default LineChart;