import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "./supabaseClient";
import BarAbnStatus from "./graphs/BarAbnStatus";
import BarEntityType from "./graphs/BarEntityType";
import BarAddState from "./graphs/BarAddressState";
import BarGstStatus from "./graphs/BarGstStatus";
import AbnTablePreview from "./TableResults";
import DownloadResults from "./DownloadResults";
import Navbar from "./components/NavbarBack";

function Navigation() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("ABNStatus");
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        setUserId(session.user.id);
        console.log(session.user.id);
        console.log(userId);
        // Log the user email to the console
      } else if (event === "SIGNED_OUT") {
        setUserId(null);
      }
    });
  }, [navigate]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const tabs = [
    { name: "ABN Status", id: "ABNStatus" },
    { name: "Entity Type", id: "EntityType" },
    { name: "Address State", id: "State" },
    { name: "GST Status", id: "gststatus" },
    { name: "Table View", id: "table" },
  ];

  return (
    <div className="space-x-0.2">
      <div className="mb-4">
        <Navbar />
      </div>
      <div>
        <div>
          <button
            className="m-1 border-2 rounded-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            onClick={() => navigate(-1)}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            Go Back
          </button>
        </div>
        <DownloadResults />
        {tabs.map((tab, tabIdx) => (
          <button
            key={tabIdx}
            onClick={() => setSelectedTab(tab.id)}
            className={classNames(
              tab.id === selectedTab
                ? "text-gray-900 border-gray-400 bg-gray-50"
                : "text-gray-500 hover:text-gray-700 border-gray-100",
              "m-2 border-2 rounded-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
            )}
          >
            {tab.name}
          </button>
        ))}
      </div>
      {selectedTab === "ABNStatus" && <BarAbnStatus />}
      {selectedTab === "State" && <BarAddState />}
      {selectedTab === "EntityType" && <BarEntityType />}
      {selectedTab === "gststatus" && <BarGstStatus />}
      {selectedTab === "table" && <AbnTablePreview />}
    </div>
  );
}

export default Navigation;
