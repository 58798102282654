import { useState, useEffect } from 'react'
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
// import { Auth } from '@supabase/auth-ui-react'
import { supabase } from './supabaseClient'
// import Login from './Login'
import Navbar from './components/NavbarBack'
import DownloadResults from './DownloadResults'
import DashboardView from './DashboardView'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'react-router-dom'
import LookupLimit from './LookupLimit'

const KeyId = process.env.REACT_APP_AWS_KEY_ID
const secretKey = process.env.REACT_APP_AWS_SECRET_ACCESS
const client = new LambdaClient({
  region: 'ap-southeast-2',
  credentials: {
    accessKeyId: KeyId,
    secretAccessKey: secretKey,
  },
})

function MyComponent() {
  const [userId, setUserId] = useState(null)
  const [buttonColor, setButtonColor] = useState('bg-red-100')
  const [textColor, setTextColor] = useState('text-red-500')
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUserId(session ? session.user : null)
      }
    )

    checkUser()

    return () => {
      authListener.subscription.unsubscribe()
    }
  })
  // }, [])

  async function checkUser() {
    const session = supabase.auth.getSession()
    setUserId(session ? session.user : null)
    setUserId(userId)
  }

  const [file, setFile] = useState(null)

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  const handleClick = async () => {
    if (!file) {
      return
    }

    const reader = new FileReader()
    reader.onload = async (event) => {
      setIsLoading(true)
      setIsLoaded(false)

      try {
        // Parse CSV file content
        const csv = event.target.result
        const lines = csv.split('\n')
        // Check if the file contains more than 200 rows
        if (lines.length > 10) {
          setShowModal(true)
          return
        }
        const firstColumn = lines.slice(1).map((line) => line.split(',')[0]) // Skip the first row and get the first column

        const command = new InvokeCommand({
          FunctionName: 'DockerLambdaAwsStack-DockerFuncF47DA747-0eKF68KQqHOF',
          Payload: JSON.stringify({
            body: {
              data: JSON.stringify(firstColumn), // Stringify the firstColumn array
              userId: JSON.stringify(userId.id), // Add the userId to the payload
            },
          }),
        })

        const response = await client.send(command)
        setIsLoaded(true)
        const payload = new TextDecoder('utf-8').decode(response.Payload)
        const payloadJson = JSON.stringify({
          body: {
            data: JSON.stringify(firstColumn), // Stringify the firstColumn array
            userId: JSON.stringify(userId.id), // Add the userId to the payload
          },
        })
        const secondCommand = new InvokeCommand({
          FunctionName: 'LambdaAwsAsic-DockerFunc2080FF3C31-XRWWsfRRBj1b', // Replace with your second Lambda function's name
          Payload: payload, // Pass the payload from the first function to the second
        })
        const secondResponse = await client.send(secondCommand)
        console.log('Response:', response)
        console.log('asic:', secondResponse)
        console.log('Payload:', payload)
      } catch (error) {
        console.error('Error:', error)
      } finally {
        setIsLoading(false)
      }
    }
    reader.readAsText(file)
  }
  const handleReset = async () => {
    setButtonColor('bg-gray-500')
    setTextColor('text-white')
    setMessage('Search has been reset!')
    try {
      const { data, error } = await supabase
        .from('abn_alpha_lookup')
        .delete()
        .eq('user_id', userId.id)
      if (error) {
        throw error
      }
      console.log('All records deleted:', data)

      // Check if there are records in the table
      if (data && data.length > 0) {
        // If there are records, set the button color to red
        setButtonColor('bg-red-500')
      } else {
        // If there are no records, set the button color to green
        setButtonColor('bg-gray-500')
        setTextColor('text-white')
      }
    } catch (error) {
      console.error('Error deleting records:', error)
    }
  }

  return (
    <> <div>
      </div>
      {
        <div className="space-x-0.1">
          <div className="mb-0.5">
            <Navbar />
          </div>
          <div
            className="content-center items-center justify-center mb-5"
            style={{
              background: 'linear-gradient(to right, #2c82f2 ,#2c32f2)',
            }}
          >
            <h1 className="mt-0.5 flex text-gray-100  font-semibold text-3xl content-center items-center justify-center font-mono">
              FlowKAI -ABN Alpha Lookup
            </h1>
            <div className={' flex flex-wrap justify-between'}>
              <p className="mt-4 mx-2 px-4 text-lg font-normal text-gray-100 content-center items-center justify-center">
                Follow the three simple steps below to start your ABN search
              </p>
              <></>
              <Link
                to="/full-screen-results"
                className="md:m-1 sm:m-2 border-2 rounded-lg group relative overflow-hidden bg-white md:py-0.5 sm:py-1 md:px-4 sm:px-2 text-center text-m font-medium hover:bg-gray-50"
                role="menuitem"
              >
                Full Screen Results
              </Link>
            </div>
          </div>

          <div className="flex flex-wrap space-x-0.2 border border-red  h-full">
            <div className="w-full sm:w-1/2 md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-1 border-2 bg-gradient-to-b from-gray-200 to-gray-50  rounded">
              <p className="mt-4 mx-2 px-2 text-base font-normal  text-gray-800">
                1 . Click the reset button to clear previous research
              </p>
              <div className="flex justify-center items-center">
                {' '}
                {message && <p>{message}</p>}
              </div>
              <div className="flex justify-center items-center">
                <button
                  onClick={handleReset}
                  className={`mt-4 mx-2 border-2 p-2 font-mono ${buttonColor} ${textColor} border-gray-400 bg-gray-50 rounded`}
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-1 border-2 bg-gradient-to-b from-gray-200 to-gray-50  rounded">
              <p className="mt-4 mb-4 mx-2 px-2 text-base font-normal  text-gray-800">
                2 . Select your ABN list in CSV format with the ABN number in
                the first column
              </p>
              <div className="  mt-4 flex justify-center items-center">
                <p className="mt-1 mb-1 mx-2 px-2 text-sm font-normal  text-gray-800">
                  sample format:
                </p>
                <img
                  src="/abn_format_screenshot.png"
                  className="md:w-3/16 lg:w-3/16"
                />
              </div>
              <div className=" mt-4 mb-2 flex justify-center items-center  bg-gray-200">
                <input type="file" onChange={handleFileChange} />
              </div>
            </div>

            <div className="w-full sm:w-1/2 md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-1 border-2 bg-gradient-to-b from-gray-200 to-gray-50  rounded">
              <p className="mt-4 mx-2 px-2 text-lg font-normal  text-gray-500">
                3 . Click in Search to start the process
              </p>
              <div className="flex justify-center items-center">
                <button
                  onClick={handleClick}
                  className="mt-4 mx-2 border-2 p-2  bg-blue-500 text-white rounded"
                >
                  Search
                </button>
                <LookupLimit showModal={showModal} />
              </div>
              <div className="flex justify-center items-center">
                {isLoading && (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-40 h-40 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              {isLoaded && (
                <div className="mt-4 flex justify-center items-center">
                  <p>
                    Data has been loaded.
                    <ScrollLink
                      to="DashboardResults"
                      smooth={true}
                      duration={500}
                      className="mt-4 mx-2 border-2 p-2  bg-blue-500 text-white rounded cursor-pointer"
                    >
                      {' '}
                      See Results
                    </ScrollLink>
                  </p>
                </div>
              )}
            </div>
          </div>
          <section id="DashboardResults" className="text-gray-600 body-font">
            <div>
              <DownloadResults />{' '}
            </div>

            <div>
              <DashboardView />{' '}
            </div>

            {/* <div className="border-2 p-2 ">
          <AbnTablePreview />
        </div> */}
          </section>
        </div>
      }
    </>
  )
}

export default MyComponent
